import React, { Component } from 'react';
import '../../App.scss';
import './GetStarted.scss';

import styled from 'styled-components'

import ColorSelectionPanel from '../ColorSelectionPanel/ColorSelectionPanel';
import HexEdit from '../HexEdit/HexEdit';

import CrossIcon from '../../assets/icons/cross.svg';
import UA5Logo from '../../assets/icons/ua5.svg';

const ColorSelection = styled.div`
  color: ${props => props.labelColor};
  background: ${props => props.backgroundColor};
  
  & button.button--text-only { 
    color: ${props => props.labelColor}; 
    // border-color: ${props => props.labelColor};
    text-decoration-color: ${props => props.labelColor};
  }
`

const Ua5Link = styled.a`
  color: black;
  text-decoration: none;
  font-family: 'BauPro';
  display: block;
  margin-top: 80px;
  span {
    text-decoration: underline;
  }
`

const HexEditorWrapper = styled.div`
  display: ${props => props.editStatus ? "flex" : "none"};
`

class GetStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiple: false,
      inputCount: Math.max(3, this.props.currentHexArray.length),
      editorsShown: {
        0: false,
        1: false
      }
    };

    this.keyEvents = this.keyEvents.bind(this);

    this.triggerMultiple = this.triggerMultiple.bind(this);
    this.addInput = this.addInput.bind(this);
    this.deleteInput = this.deleteInput.bind(this);
    this.toggleEditors = this.toggleEditors.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
  }

  keyEvents(event){
      if(event.keyCode === 27 && this.state.multiple) {
        this.triggerMultiple(event);
      }
  }

  componentDidMount(){
      document.addEventListener("keydown", this.keyEvents, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.keyEvents, false);
  }

  handleEditSubmit(index) {
    return e => {
      e.preventDefault();
      this.toggleEditors(index);
    }
  }
  
  toggleEditors(index) {
    let newEditorsShown = this.state.editorsShown;
    newEditorsShown[index] = !newEditorsShown[index];
    this.setState({
      editorsShown: newEditorsShown
    });
  }

  triggerMultiple(e) {
    e.preventDefault();
    this.setState({
      multiple: !this.state.multiple
    });
  }

  renderColorSquare(index) {
    let borderWidthStyle = { borderWidth: this.props.borderWidth };
    if (this.props.hexItems[index]) {
      return (
        // color already chosen
        <div 
          className="get-started__square-container get-started__square-container--selected"
          style={borderWidthStyle}>
          <ColorSelection 
            className="get-started__square"
            backgroundColor={"#" + this.props.hexItems[index].text}
            labelColor={"#" + this.props.hexItems[index].label}>
            <button 
              className="button--text-only" 
              onClick={() => this.toggleEditors(index)}>
              Edit
            </button>
            <p className="text--small-bold">
              #{this.props.hexItems[index].text.toUpperCase()}
            </p>
            <div></div>
          </ColorSelection>
          <HexEditorWrapper 
            className="get-started__edit-wrapper"
            editStatus={this.state.editorsShown[index]}>
            <HexEdit
              handleAddCurrent={this.props.handleAddCurrent}
              handleHexEdit={this.props.handleHexEdit}
              hexItems={this.props.hexItems}
              currentHex={this.props.currentHex}
              editIndex={index}
              handleEditSubmit={this.handleEditSubmit(index)}
              squareSize={this.props.squareSize}
              borderWidth={this.props.borderWidth}
              getStarted={true}/>
          </HexEditorWrapper>
        </div>
      );
    }
    else {
      return (
        // color not chosen
        <div className="get-started__square-container">
          <ColorSelectionPanel
            index={index}
            handleAddCurrent={this.props.handleAddCurrent}
            handleHexEdit={this.props.handleHexEdit}
            hexItems={this.props.hexItems}
            handleHexAdd={this.props.handleHexAdd}
            currentHex={this.props.currentHex}
            squareSize={this.props.squareSize}
            borderWidth={this.props.borderWidth}
            getStarted={true}/>
        </div>
      );
    }
  }

  renderMultipleInputs() {
    let inputArray = [];
    for (let i = 0; i < this.state.inputCount; i++) {
      inputArray.push(
        <div className="color-selection__input-wrapper" key={i}>
          <input 
            type="text" 
            // key={i} 
            id={i} 
            value={this.props.currentHexArray[i]}
            onChange={this.props.handleArrayInputs} />
          <button 
            className="multipleInput__delete button--anchor"
            onClick={() => this.deleteInput(i)}>
            <img 
              className="img-svg" 
              src={CrossIcon} 
              alt="Cross icon" />
          </button>
        </div>
      )
    }
    return inputArray;
  }

  addInput() {
    let newCount = this.state.inputCount + 1;
    this.setState({
      inputCount: newCount
    });
  }

  deleteInput(key) {
    this.props.deleteArrayInput(key);
    this.setState({
      inputCount: this.props.currentHexArray.length
    });
  }

  renderButtonClass() {
    let validInputs = [];
    let allValidHex = true;

    if (this.state.multiple) {
      this.props.currentHexArray.forEach(hex => {
        // Ignore blank input fields
        if (hex.length !== 0) {
          // convert hex if 3 digits
          let hexSixDigit = (hex.length === 3) ? hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] : hex;
          if (/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hexSixDigit))
            validInputs.push(hexSixDigit);
          else
            allValidHex = false;
        }
      });
      let uniqueValidInputs = [...new Set(validInputs)];
      return (uniqueValidInputs.length >= 2 && allValidHex) ? "" : "get-started-button--hidden";
    }
    else
      return (this.props.hexItems.length >= 2 ? "" : "get-started-button--hidden")
  }

  renderSelectionPanel() {
    if (this.state.multiple) {
      return (
        // Multiple text inputs
        <section 
          className="get-started__selection get-started__selection--multiple">
          <button 
            className="button--text-only link--black" 
            onClick={this.triggerMultiple}>
            Close
          </button>
          <div className="color-add--multiple flex-container--column">
            {this.renderMultipleInputs()}
            <button 
              className="button--text-only link--black text--small" 
              onClick={this.addInput}>
              Add another color
            </button>
            <button 
              className={"get-started-button get-started-button--multiple " + this.renderButtonClass()}
              onClick={this.props.handleAddHexArray}>
              Get started
            </button>
          </div>

          <div></div>
        </section>

        // <MultipleInputPanel 
        //   triggerMultiple={this.triggerMultiple}
        //   currentHexArray={this.props.currentHexArray}
        //   handleArrayInputs={this.props.handleArrayInputs}
        //   addInput={this.addInput}
        //   deleteInput={this.deleteInput}
        //   handleAddHexArray={this.props.handleAddHexArray}
        //   renderButtonClass={this.renderButtonClass}/>
      )
    }
    else {
      return (
        // Two color picker inputs
        <section className="get-started__selection">
          {this.renderColorSquare(0)}
          {this.renderColorSquare(1)}
        </section>
      );
    }
  }

  render() {
    return (
      <section className="get-started__container" role="main">
        <section className="get-started__sidebar">
          <div className="get-started__sidebar__content">
            <h1 className="get-started__title">Accessible Brand Colors</h1>
            <span className="get-started--small-margin">
              <p className="get-started__subtitle">This tool shows you how ADA compliant your colors are in
                relation to each other. By adding your brand’s colors on the right, you can generate a chart to see how they can be used together for accessibility, and find similar colors that work better.</p>
              <p className="get-started__multiple-trigger">
                Power user? 
                <button 
                  className="button--text-only link--black" 
                  onClick={this.triggerMultiple}>
                  Add multiple colors at once.
                </button>
              </p>
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <Ua5Link href="https://useallfive.com/" target="_blank">Created by <span>Use All Five</span> - a Los Angeles-based design and technology studio that creates brand identities and digital experiences across spaces and screens.</Ua5Link>
              <button 
                className={"get-started-button " + this.renderButtonClass()}
                onClick={this.props.handleExitGetStarted}>
                Get started
              </button>
            </span>
          </div>
          
          <a 
            href="https://useallfive.com/"
            className="ua5-logo">
            <img 
              className="img-svg" 
              src={UA5Logo}
              alt="Use All Five Logo" />
          </a>
        </section>
        {this.renderSelectionPanel()}
      </section>
    );
  }
}

export default GetStarted;