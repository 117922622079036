import React, { Component } from 'react';
import '../../App.scss';
import './HexAdd.scss';

import styled from 'styled-components';
import { ChromePicker } from 'react-color';

const HexAddForm = styled.form`
  width: ${props => props.scaleInfo.width};
  
  & .chrome-picker { 
    width: ${props => props.scaleInfo.width} !important; 

    & > div:nth-child(1) {
      transform: scaleY(${props => props.scaleInfo.scale});
      transform-origin: bottom;
    }
  }
`

class HexAdd extends Component {
  constructor(props) {
    super(props);
    this.defaultSize = 254;
  }

  assignHexAddClass() {
    return (this.props.squareSize <= this.defaultSize) ? 
      "hex-add__form--absolute" : 
      "hex-add__form--centered";
  }

  calcPickerStyle() {
    let squareSize = this.props.squareSize;
    let containerWidth;

    if (squareSize > this.defaultSize || this.props.getStarted) {
      return {width: this.defaultSize + "px", scale: 1};
    }
    else {
      for (let currSquares = 1; currSquares <= this.props.maxSquares; currSquares++) {
        if (squareSize * currSquares > this.defaultSize) {
          containerWidth = squareSize * currSquares;
          break;
        }
      }
    }

    let gradientCurrentHeight = 0.5415 * (containerWidth);
    let bottomHeight = (containerWidth * 0.1275) + 75;
    let gradientFinalHeight = containerWidth - bottomHeight;
    let gradientScale = gradientFinalHeight / gradientCurrentHeight;
    
    return { width: containerWidth + "px", scale: gradientScale };
  }

  render() {
    return (
      <div className="hex-add__container"> 
        <HexAddForm 
          className={"hex-add__form " + this.assignHexAddClass()}
          onSubmit={this.props.handleAddCurrent}
          squareSize={this.props.squareSize}
          scaleInfo={this.calcPickerStyle()}>
          <ChromePicker 
            disableAlpha={true}
            color={this.props.currentHex.text}
            onChangeComplete={this.props.handleHexAdd} />
          <div className="hex-add__button-container">
            <button 
              className="hex-add__button" 
              onClick={this.props.closePicker}>
              Cancel
            </button>
            <button 
              className="hex-add__button" 
              type="submit">
              Add hex
            </button>
          </div>
        </HexAddForm>
      </div>
    );
  }
}

export default HexAdd;