import React, { Component } from 'react';
import '../../App.scss';
import './GradeIcon.scss';

import CheckIcon from '../../assets/icons/check.svg';
import CrossIcon from '../../assets/icons/cross.svg';

// import styled from 'styled-components';

class GradeIcon extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let grade = this.props.grade;
    return (
      <div className={"grade-icon grade-icon--" + grade}>
        <div className="grade-icon__img-wrapper">
          <img
            className="img-svg"
            src={(grade === "DNP") ? CrossIcon : CheckIcon}
            alt={(grade === "DNP") ? "Cross icon" : "Check icon"} />
        </div>
        <p>{grade}</p>
      </div>
    );
  }
}

export default GradeIcon;