import React, { Component } from 'react';
import RowItem from '../RowItem/RowItem';
import styled from 'styled-components';
import GridHeader from '../GridHeaders/GridHeader';

import './HexGrid.scss'

const HexGridContainer = styled.div`
  grid-template:
    minmax(0, 1fr) minmax(0, ${props => props.hexLength}fr) /
    minmax(0, 1fr) minmax(0, ${props => props.hexLength}fr);

  & .hex-grid__header--first {
    border-width: ${props => props.borderWidth}px;

    &__x-label,
    &__y-label {
      display: ${props => props.squareSize < 100 ? 'none' : 'block'};
    }
  }
  
  & .hex-grid__header--text,
  & .hex-grid__header--background,
  & .hex-grid__row {
    &::after, &::before { 
      border-width: ${props => props.borderWidth}px; 
    }
  }

  & .hex-grid__header--text,
  & .hex-grid__row {
    grid-template: 100% / repeat(${props => props.hexLength}, minmax(0, 1fr));
  }

  & .hex-grid__header--background,
  & .hex-grid__row-container {
    grid-template: repeat(${props => props.hexLength}, minmax(0, 1fr)) / 100%;
  }
`

class HexGrid extends Component {
  constructor(props) {
    super(props);

    this.addColorRow = this.addColorRow.bind(this);
    this.getHeaders = this.getHeaders.bind(this);
  }

  addColorRow(item) {
    let hexLength = this.props.hexItems.length;
    let itemsInRow = [];

    this.props.hexItems.forEach((entry, index) => {
      let ratio = this.props.getContrastRatio(item.text, entry.text).toFixed(2);

      let coordinates = { 
        x: index + 1,
        y: 1 
      };

      while (this.props.hexItems[coordinates.y - 1] !== item) {
        coordinates.y++;
      }

      itemsInRow.push(
        <RowItem 
          key={entry.text + '-' + index.toString()} 
          handleAddCurrent={this.props.handleAddCurrent}
          handleHexAdd={this.props.handleHexAdd}
          currentHex={this.props.currentHex}
          gradesDisplayed={this.props.gradesDisplayed}
          background={item} 
          entry={entry}
          ratio={ratio}
          labelColor={item.label}
          hexItems={this.props.hexItems}
          lastEntry={((index+1) === hexLength)}
          squareSize={this.props.squareSize}
          borderWidth={this.props.borderWidth}
          coordinates={coordinates}
          />
      );
    })

    return (
      <div className="hex-grid__row" key={item.key}>
        {itemsInRow}
      </div>
    );
  }

  getHeaders(hexhexItems, type) {
    return (
      hexhexItems.map(
        (color, index) => {
          let coordinates = {
            x: (type === 'text') ? index+1 : 0,
            y: (type === 'background') ? index+1 : 0
          }
          return (
            <GridHeader
              key={index} 
              index={index}
              backgroundColor={color.text}
              labelColor={color.label}
              handleHexEdit={this.props.handleHexEdit}
              hexItems={hexhexItems}
              deleteHex={() => this.props.deleteHex(color.key)}
              squareSize={this.props.squareSize}
              borderWidth={this.props.borderWidth}
              coordinates={coordinates} />
          );
        }
      )
    )
  }

  render() {
    const hexhexItems = this.props.hexItems
    const hexLength = hexhexItems.length;

    const hexGridRows = hexhexItems.map(this.addColorRow);
    const textHeaders = this.getHeaders(hexhexItems, 'text');
    const backgroundHeaders = this.getHeaders(hexhexItems, 'background');

    return (
      <HexGridContainer 
        className="hex-grid__container" 
        hexLength={hexLength}
        squareSize={this.props.squareSize}
        borderWidth={this.props.borderWidth}>
        <div 
          id="hex-grid__header--first"
          className="hex-grid__header--first">
          <p className="text--small hex-grid__header--first__x-label">Text</p>
          <p className="text--small hex-grid__header--first__y-label">Background</p>
        </div>

        <div className="hex-grid__header--text">
          {textHeaders}
        </div>

        <div className="hex-grid__header--background">
          {backgroundHeaders}
        </div>

        <div id="hex-grid" className="hex-grid__row-container">
          {hexGridRows}
        </div>
      </HexGridContainer>
    );
  }
}

export default HexGrid;