import React, { Component } from 'react';
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import Clipboard from 'react-clipboard.js';
import { Tooltip } from 'react-tippy';
import './App.scss';
import 'react-tippy/dist/tippy.css';

import HexGrid from './components/HexGrid/HexGrid';
import GradeToggles from './components/GradeToggles/GradeToggles';
import GetStarted from './components/GetStarted/GetStarted';
// import MainPage from './components/MainPage/MainPage';

import UA5Logo from './assets/icons/ua5.svg';

const history = createBrowserHistory();

class App extends Component {
  constructor() {
    super()
    this.state = {
      showGetStarted: true,
      hexItems: [],
      currentHex: {
        text: '000000', 
        key: Date.now(), 
        label: ''
      },
      currentHexArray: [],
      gradesDisplayed: {
        AAA: true,
        AA: true,
        AA18: true,
        DNP: true
      },
      squareSize: -1,
      borderWidth: 4
    }

    // When to switch from 4px to 2px border widths
    this.cutoffSize = 150;

    this.getContrastRatio = this.getContrastRatio.bind(this);
    this.handleExitGetStarted = this.handleExitGetStarted.bind(this);
    this.deleteArrayInput = this.deleteArrayInput.bind(this);
    this.calcSquareSize = this.calcSquareSize.bind(this);
  }

  /* Component Lifecycle */
  componentDidMount() {
    this.getQueryParamsColors();
    this.calcSquareSize();
    window.addEventListener('resize', this.calcSquareSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calcSquareSize);
  }

  /* Query Parameter Related */
  getQueryParamsColors() {
    // Check for query params
    const parsed = queryString.parse(window.location.search);
    if (parsed["colors[]"]) {
      // Create array of valid hex codes
      let currentHexArray = parsed["colors[]"].split(",").filter(this.checkHexValid);

      this.setState({ currentHexArray }, () => { 
        this.addHexArray(); 
      });
      
      // Check if enough colors to skip initial page
      if (currentHexArray.length >= 2)
        this.exitGetStarted();
    }
  }

  updateQueryParams() {
    let newQueryString = "";
    if (this.state.hexItems.length > 0) {
      newQueryString = "?colors[]=";
      this.state.hexItems.forEach((hex, index) => {
        if (index !== 0) { newQueryString += ","; }
        newQueryString += hex.text.toUpperCase();
      });
    }
    history.push(newQueryString);
  }


  /* Regex and existence validation functions */
  checkHexValid(hex) {
    if (/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex))
      return (/^(?:[0-9a-fA-F]{3}){1,2}$/.test(hex));
    else
      console.error("Invalid Hex: " + hex);
  }

  checkHexExists(newHex) {
    let hexItems = this.state.hexItems;
    for (let i = 0; i < hexItems.length; i++) {
      if (hexItems[i].text.toLowerCase() === newHex.toLowerCase()) {
        console.error("Hex already exists: " + newHex);
        return false;
      }
    }
    return true;
  }

  /* UI handler functions */

  handleExitGetStarted(e) {
    e.preventDefault();
    this.exitGetStarted();
  }

  handleToggleUpdate = e => {
    let gradesDisplayed = this.state.gradesDisplayed;
    gradesDisplayed[e.target.value] = e.target.checked;
    this.setState({ gradesDisplayed });
  }

  /* UI functions */

  exitGetStarted() {
    this.setState({ showGetStarted: false }, () => { 
      this.calcSquareSize(); 
    });
  }

  calcSquareSize() {
    let squareSize = 0;
    let headerElement = document.getElementById('hex-grid__header--first');

    // case for GetStarted page: calculate square size by dividing min
    // viewport length and dividing by 2
    if (this.state.showGetStarted) {
      let minSize = Math.min(window.innerHeight, window.innerWidth);
      squareSize = minSize / 2;
    }
    // for more cases: get square size from first header
    else if (typeof(headerElement) != 'undefined' && headerElement != null) {
      squareSize = headerElement.getBoundingClientRect().width;
    }
    // in case first header not accessible: calculate square size by 
    // dividing min viewport length and dividing by grid size
    else {
      let minSize = Math.min(window.innerHeight, window.innerWidth);
      squareSize = minSize / Math.max(this.state.hexItems.length + 1, 2);
    }
    this.setState({ squareSize }, this.setBorderWidth());
  }

  setBorderWidth() {
    let borderWidth = (this.state.squareSize > this.cutoffSize) ? 4 : 2;
    this.setState({ borderWidth });
  }

  /* Color API Replacement Functions */

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }

  luminanace(r, g, b) {
    var a = [r, g, b].map((v) => {
      v /= 255;
      return (v <= 0.03928) ? v / 12.92 : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  getContrastRatio(hex1, hex2) { 
    let rgb1 = this.hexToRgb(hex1);
    let rgb2 = this.hexToRgb(hex2);
    var result = 
      (this.luminanace(rgb1[0], rgb1[1], rgb1[2]) + 0.05)/ 
      (this.luminanace(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
    return (result < 1) ? 1/result : result;
  }

  // Label colors

  getLabelColor(hex) {
    let lightContrast = this.getContrastRatio(hex, 'ffffff');
    let darkContrast = this.getContrastRatio(hex, '000000');
    return (lightContrast > darkContrast) ? "ffffff" : "000000";
  }

  updateLabelColor(index) {
    let hexItems = this.state.hexItems;
    hexItems[index].label = this.getLabelColor(hexItems[index].text);
    this.setState({ hexItems });
  }

  /* Color Selection Event Handlers */
  
  handleHexAdd = e => {
    const itemText = e.hex.replace(/#/g, '');
    const currentHex = { text: itemText, key: Date.now(), label: '' };
    this.setState({ currentHex });
  }

  handleHexEdit = (index) => {
    return e => {
      const itemText = e.hex.replace(/#/g, '');
      if (this.checkHexExists(itemText)) {
        let newItems = this.state.hexItems;
        newItems[index].text = itemText;
  
        this.setState({ hexItems: newItems }, () => { 
          this.updateLabelColor(index);
          this.updateQueryParams();
        });
      }
    }
  }

  handleArrayInputs = e => {
    let currentHexArray = this.state.currentHexArray;
    currentHexArray[e.target.id] = e.target.value;
    this.setState({ currentHexArray });
  }

  /* Color Submission handlers */

  handleAddCurrent = e => {
    e.preventDefault();
    this.addCurrentHex();
  }

  handleAddHexArray = e => {
    e.preventDefault();
    this.addHexArray();
  }

  // Multiple input field management
  deleteArrayInput(index) {
    let currentHexArray = this.state.currentHexArray;
    currentHexArray.splice(index, 1);
    this.setState({ currentHexArray });
  }

  /* Add/Edit/Remove colors */
  addCurrentHex() {
    let currentHex = this.state.currentHex;
    let hexItems = this.state.hexItems;

    // Check for validity, existence, and whether user is at max
    if (this.checkHexValid(currentHex.text) && this.checkHexExists(currentHex.text) && (hexItems.length < 10) ) {
      // Get label color
      currentHex.label = this.getLabelColor(currentHex.text);
      const newItems = [...hexItems, currentHex];

      this.setState({ hexItems: newItems }, () => {
        this.updateQueryParams();
        this.calcSquareSize();
      });
    }
  }

  addHexArray() {
    // Clears hexItems added from ColorSelectionPanels
    this.setState({ hexItems: [] });

    this.state.currentHexArray.forEach(hex => {
      setTimeout(() => {
        // converts 3 digit hex to 6 digit
        let newHex = (hex.length === 6) ? hex : hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        let newItem = {text: newHex, key: Date.now(), label:''};
        this.setState({ currentHex: newItem }, () => {
          this.addCurrentHex();
        });
      }, 1);
    });

    this.setState({ currentHexArray: [] }, () => {
      this.exitGetStarted();
    })
  }

  deleteHex = key => {
    const hexItems = this.state.hexItems.filter(item => {
      return (item.key !== key);
    });
    
    this.setState({
      hexItems,
      showGetStarted: (hexItems.length < 2)
    }, () => { 
      this.updateQueryParams(); 
      this.calcSquareSize();
    });
  }

  startOver() {
    this.setState({ 
      showGetStarted: true,
      hexItems: [],
      currentHex: {
        text: '000000', 
        key: Date.now(), 
        label: ''
      },
      currentHexArray: [],
      gradesDisplayed: {
        AAA: true,
        AA: true,
        AA18: true,
        DNP: true
      }
    }, () => {
      this.updateQueryParams();
      this.calcSquareSize();
    });
  }

  shareURL() {
    // TODO
    navigator.clipboard.writeText(window.location.href);
    alert("URL Copied!");
  }


  /* Content rendering functions */
  renderContent() {
    if (this.state.showGetStarted) {
      // Render initial page
      return (
        <GetStarted 
          handleExitGetStarted={this.handleExitGetStarted}//
          hexItems={this.state.hexItems}
          handleAddCurrent={this.handleAddCurrent}
          handleAddHexArray={this.handleAddHexArray}
          handleHexAdd={this.handleHexAdd}
          handleHexEdit={this.handleHexEdit}
          handleArrayInputs={this.handleArrayInputs}
          currentHex={this.state.currentHex}
          currentHexArray = {this.state.currentHexArray}
          deleteArrayInput = {this.deleteArrayInput}
          squareSize={this.state.squareSize}
          borderWidth={this.state.borderWidth}/>
      );
    }
    else {
      // Render grid page
      return (
        <section className="main-page__container">
          <section className="main-page__sidebar">
            <span className="sidebar__top">
              <h1>Accessible Brand Colors</h1>
              <GradeToggles
                gradesDisplayed={this.state.gradesDisplayed} 
                handleToggleUpdate={this.handleToggleUpdate} />
            </span>
            <span className="sidebar__bottom">
              <div className="sidebar__actions">
                <button 
                  className="button--text-only text--small"
                  onClick={() => this.startOver()}>
                  Start Over  
                </button>
                <Tooltip
                  title="Copied to clipboard!"
                  trigger="click"
                  arrow="true"
                  animation="fade">
                  <Clipboard 
                    className="button--text-only text--small" 
                    data-clipboard-text={window.location.href}>
                    Share
                  </Clipboard>
                </Tooltip>
              </div>
              <a 
                href="https://useallfive.com/"
                className="ua5-logo">
                <img 
                  className="img-svg" 
                  src={UA5Logo}
                  alt="Use All Five Logo" />
              </a>
            </span>
          </section>
          <HexGrid 
            handleAddCurrent={this.handleAddCurrent}
            handleHexAdd={this.handleHexAdd}
            handleHexEdit={this.handleHexEdit}
            currentHex={this.state.currentHex}
            currentHexArray = {this.state.currentHexArray}
            getContrastRatio={this.getContrastRatio}
            gradesDisplayed={this.state.gradesDisplayed}
            hexItems={this.state.hexItems}
            deleteHex={this.deleteHex} 
            squareSize={this.state.squareSize}
            borderWidth={this.state.borderWidth}/>
        </section>
        // <MainPage
        //   handleAddCurrent={this.handleAddCurrent}
        //   handleHexAdd={this.handleHexAdd}
        //   handleHexEdit={this.handleHexEdit}
        //   gradesDisplayed={this.state.gradesDisplayed}
        //   currentHex={this.state.currentHex}
        //   currentHexArray = {this.state.currentHexArray}
        //   getContrastRatio={this.getContrastRatio}
        //   hexItems={this.state.hexItems}
        //   deleteHex={this.deleteHex} 
        //   squareSize={this.state.squareSize}
        // />
      )
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          {this.renderContent()}
        </div>
      </Router>
    )
  }
}

export default App;
