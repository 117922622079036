import React, { Component } from 'react';
import '../../App.scss';
import './RowItemOverlay.scss';

import GradeIcon from '../GradeIcon/GradeIcon';

import styled from 'styled-components';

const RowItemOverlayContainer = styled.div`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  height: ${props => props.overlayInfo.overlaySize}px;
  width: ${props => props.overlayInfo.overlaySize}px;
  right: ${props => props.overlayInfo.overflow.x * 100}%;
  top: ${props => (props.overlayInfo.overflow.y + 1) * -100}%;
  padding: 0 ${props => props.overlayInfo.overlaySize * 0.075}px;
  &::after, &::before { 
    border-width: ${props => props.borderWidth}px !important; 
  }

  & .overlay__close-button,
  & .overlay__ratio-label,
  & .overlay__ratio {
    color: ${props => props.theme.labelColor} !important;
  }

  // & .overlay__background {
  //   top: ${props => props.coordinates.y * -100}%;
  //   left: ${props => props.coordinates.x * -100}%;
  // }
`
class RowItemOverlay extends Component {
  constructor(props) {
    super(props);
    this.minOverlaySize = 300;
  }

  renderOverlayGrades() {
    let grade = this.props.contrast.grade;

    return (grade === 'AAA') ? (
      <div className="overlay__grade-container">
        <GradeIcon grade="AAA" />
        <GradeIcon grade="AA" />
      </div>
    ) : (
      <div className="overlay__grade-container">
        <GradeIcon grade={grade} />
      </div>
    )
  }

  getMaxOverlaySize() {
    let hexGridElement = document.getElementById('hex-grid');
    let maxOverlaySize;

    // for more cases
    if (typeof(hexGridElement) != 'undefined' && hexGridElement != null) {
      // maxOverlaySize = hexGridElement.offsetWidth;
      maxOverlaySize = hexGridElement.getBoundingClientRect().width;
    }
    // fallback: min viewport length - 1 row (less accurate);
    else {
      let minSize = Math.min(window.innerHeight, window.innerWidth);
      maxOverlaySize = minSize - this.props.squareSize;
    }

    return (maxOverlaySize);
  }

  calcOverlayInfo() {
    let maxLength = this.props.maxLength;
    let squareSize = this.props.squareSize;

    let overlaySize = 0;
    let squaresNeeded = 1;

    let maxOverlaySize = Math.floor(this.getMaxOverlaySize());

    for (; squaresNeeded <= maxLength; squaresNeeded++ ) {
      console.log(squaresNeeded, squareSize * squaresNeeded, maxOverlaySize);

      if ((squareSize * squaresNeeded) >= maxOverlaySize) {
        console.log('exceed max:');
        overlaySize = maxOverlaySize;
        squaresNeeded = maxLength;
        break;
      }
      else if ((squareSize * squaresNeeded) >= this.minOverlaySize) {
        overlaySize = squareSize * squaresNeeded;
        break;
      }
    }

    let coordinates = this.props.coordinates; 

    let overflow = {
      x: Math.max(((coordinates.x + squaresNeeded - 1) - maxLength), 0),
      y: Math.max(((coordinates.y + squaresNeeded - 1) - maxLength), 0)
    }

    return { overlaySize, overflow };
  }

  render() {
    return (
      <RowItemOverlayContainer 
        className="row-item__overlay"
        overlayInfo={this.calcOverlayInfo()}
        borderWidth={this.props.borderWidth}
        coordinates={this.props.coordinates}
        theme={this.props.theme}>
        <div className="overlay__button-wrapper">
          <button 
            className="button--text-only overlay__close-button" 
            onClick={() => this.props.toggleOverlay()}>
            Close
          </button>
        </div>
        <div className="overlay__info">
          <div className="overlay__contrast-wrapper">
            <p className="overlay__ratio-label">Contrast Ratio</p>
            <p className="overlay__ratio">{this.props.contrast.ratio} : 1</p>
          </div>
          {this.renderOverlayGrades()}
          <div className="overlay__text-samples">
            <p className={"text-sample--normal " + this.props.checkSampleAvailability(4.5)}>
              {this.props.textSamples.normalText} (14pt+)
            </p>
            <p className={"text-sample--largeBold " + this.props.checkSampleAvailability(3)}>
              {this.props.textSamples.largeBoldText} (24pt+)
            </p>
            <p className={"text-sample--large " + this.props.checkSampleAvailability(3)}>
              {this.props.textSamples.largeText} (24pt+)
            </p>
          </div>
        </div>
        <div className="overlay__background" />
      </RowItemOverlayContainer>
    );
  }
}

export default RowItemOverlay;