import React, { Component } from 'react';
import '../../App.scss';
import './Grade.scss';

import GradeIcon from '../GradeIcon/GradeIcon';

class Grade extends Component {
  getSizeClass() {
    return (this.props.squareSize < 150) ? "grade__container--small" : "";
  }

  convertRatioToGrade() {
    let ratio = this.props.ratio;
    let grade = '';
    let background = '';

    if (ratio >= 7) {
      grade = 'AAA';
      background = '#57e6c1';
    }
    else if (ratio >= 4.5) {
      grade = 'AA';
      background = '#57e6c1';
    }
    else if (ratio >= 3) {
      grade = 'AA18';
      background = '#fffa90';
    }
    else {
      grade = 'DNP';
      background = '#fc825f';
    }

    return {ratio, grade, background};
  }

  render() {
    let gradeInfo = this.convertRatioToGrade();

    return(
      <div className={"grade__container " + this.getSizeClass()}>
        <GradeIcon grade={gradeInfo.grade}/>
        <div className="ratio">
          <p className="text--small">{gradeInfo.ratio}</p>
        </div>
      </div>
    );
  }
}

export default Grade;

