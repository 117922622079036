import React, { Component } from 'react';
import '../../App.scss';
import './HexEdit.scss';

import styled from 'styled-components';
import { ChromePicker } from 'react-color';

const HexEditForm = styled.form`

  &.hex-edit__form--absolute {
    // TODO: switch/if else syntax
    ${props => props.pickerTheme.relativeTo === 'top-left' ? 
      ` top: ${props.pickerTheme.topOffset}px;
        left: 0;
      ` : ``
    }
    ${props => props.pickerTheme.relativeTo === 'bottom-left'  ? 
      ` bottom: 0;
        left: 0;
      ` : ``
    }
    ${props => props.pickerTheme.relativeTo === 'top-right'  ? 
      ` top: ${props.pickerTheme.topOffset}px;
        right: 0;
      ` : ``
    }

  }
  
  & .chrome-picker { 
    width: ${props => props.pickerTheme.width}px !important; 

    & > div:nth-child(1) {
      transform: scaleY(${props => props.pickerTheme.scale});
      transform-origin: bottom;
    }
  }
`

class HexEdit extends Component {
  constructor(props) {
    super(props);
    this.defaultSize = 254;
  }

  componentDidMount() {
    const editPickers = document.getElementsByClassName('chrome-picker')

    Array.from(editPickers).forEach(function(picker) {
      picker.addEventListener('touchmove', function(e) {
        e.preventDefault();
      }, false);
    });
  }

  componentWillUnmount() {
    const editPickers = document.getElementsByClassName('chrome-picker')

    Array.from(editPickers).forEach(function(picker) {
      picker.removeEventListener('touchmove', function(e) {
        e.preventDefault();
      }, false);
    });
  }

  assignHexEditClass() {
    return (this.props.squareSize <= this.defaultSize) ? 
      "hex-edit__form--absolute" : 
      "hex-edit__form--centered";
  }

  calcPickerSize() {
    let result = {
      width: this.defaultSize,
      scale: 1,
      squares: 1,
      topOffset: 0,
      relativeTo: 'top-left'
    };
    
    let squareSize = this.props.squareSize;

    if (squareSize > this.defaultSize || this.props.getStarted)
      return result;
    else {
      for (; result.squares <= this.props.hexItems.length; result.squares++) {
        if (squareSize * result.squares > this.defaultSize) {
          result.width = squareSize * result.squares;
          break;
        }
      }
    }

    let gradientCurrentHeight = 0.5415 * (result.width);
    let bottomHeight = (result.width * 0.1275) + 75;
    let gradientFinalHeight = result.width - bottomHeight;
    result.scale = gradientFinalHeight / gradientCurrentHeight;
    result.topOffset = gradientCurrentHeight * (result.scale - 1);

    // Get greater of coordinate values.
    let headerIndex = Math.max(this.props.coordinates.x, this.props.coordinates.y);
    if ((headerIndex + result.squares -1) <= this.props.hexItems.length)
      result.relativeTo = 'top-left';
    else if (this.props.coordinates.x < this.props.coordinates.y)
      result.relativeTo = 'bottom-left';
    else
      result.relativeTo = 'top-right';

    return result;
  }

  render() {
    return (
      <div className="hex-edit__container"> 
        <HexEditForm 
          className={"hex-edit__form " + this.assignHexEditClass()}
          onSubmit={this.props.handleEditSubmit}
          squareSize={this.props.squareSize}
          pickerTheme={this.calcPickerSize()}>
          <ChromePicker 
            disableAlpha={true}
            color={this.props.hexItems[this.props.editIndex].text}
            onChangeComplete={this.props.handleHexEdit(this.props.editIndex)} />
          <button type="submit">Done</button>
        </HexEditForm>
      </div>
    );
  }
}

export default HexEdit;