import React, { Component } from 'react';
import '../../App.scss';
import './GradeToggle.scss';

import CheckedIcon from '../../assets/icons/checkbox-checked.svg';
import UncheckedIcon from '../../assets/icons/checkbox-unchecked.svg';

import GradeIcon from '../GradeIcon/GradeIcon';

class GradeToggles extends Component {
	constructor() {
    super();
    
    this.gradeLabels = {
      'AAA':  'Pass (7+)',
      'AA':   'Pass (4.5+)',
      'AA18': 'Pass, Large Text Only (3+)',
      'DNP':  'Does Not Pass'
    };
	}

	render() {
		let {gradesDisplayed, handleToggleUpdate} = this.props;
		let gradeToggles = [];

		Object.keys(gradesDisplayed).forEach((key, index) => {
			gradeToggles.push(
        <li key={index} className="grade-toggle">
          <input 
						type="checkbox" 
						className="grade-toggle__checkbox"
            value={key} 
            checked={gradesDisplayed[key]} 
            onChange={handleToggleUpdate} />
					<div className="grade-toggle__svg-checkbox">
						<img
							className="img-svg"
							src={gradesDisplayed[key] ? CheckedIcon : UncheckedIcon}
							alt={gradesDisplayed[key] ? "Checked icon" : "Unchecked icon"} />
					</div>
					<span className="grade-toggle__label-container">
						<GradeIcon grade={key}/>
						<label className="grade-toggle__text">{this.gradeLabels[key]}</label>
					</span>
				</li>
			);
		});

		return (
			<div>
				<p className="text--small-bold">View compliance level:</p>
				<ul className="grade-toggle__container">
					{gradeToggles}
				</ul>
			</div>
		);
	}
}

export default GradeToggles;