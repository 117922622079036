import React, { Component } from 'react';
import '../../App.scss';
import './ColorSelectionPanel.scss';

import styled from 'styled-components';

import HexAdd from '../HexAdd/HexAdd';

import AddIcon from '../../assets/icons/add.svg';

const ColorSelection = styled.div`
  & .color-selection__square-wrapper > p {
    display: ${props => props.theme.squareSize < 200 ? "none" : "block"};
    bottom: ${props => props.theme.textSpacing}px;
  }

  & .color-selection__add {
    width: ${props => props.theme.squareSize * 0.5}px;
    height: ${props => props.theme.squareSize * 0.5}px;
    max-width: ${props => props.theme.iconMaxSize}px;
    max-height: ${props => props.theme.iconMaxSize}px;
  }
`

class ColorSelectionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = { pickerStatus: false };
    this.togglePicker = this.togglePicker.bind(this);
  }

  getPlaceText(index) {
    let place = index;
    if (index > 10 && index < 20) 
      place += "th";
    else {
      switch ((index) % 10) {
        case 1:
          place += "st";
          break;
        case 2:
          place += "nd";
          break;
        case 3:
          place += "rd";
          break;
        default:
          place += "th";
      }
    }

    return "Select " + place + " color";
  }

  calcPanelStyle() {
    let squareSize = this.props.squareSize;

    let iconMaxSize;
    if (squareSize > 300)
      iconMaxSize = 100;
    else if (squareSize > 250)
      iconMaxSize = 85;
    else if (squareSize > 200)
      iconMaxSize = 70;
    else
      iconMaxSize = 65;

    let textSpacing;
    if ((squareSize * 0.5) > iconMaxSize)
      textSpacing = (squareSize - iconMaxSize) / 4;
    else
      textSpacing = (squareSize * 0.5) / 4;

    return {squareSize, iconMaxSize, textSpacing}
  }

  togglePicker() {
    let pickerStatus = !this.state.pickerStatus
    this.setState({ pickerStatus });
  }

  renderPanelContent() {
    return (this.state.pickerStatus) ? (
      // Show color picker
      <div className="color-selection__square-wrapper">
        <HexAdd 
          handleAddCurrent={this.props.handleAddCurrent}
          handleHexAdd={this.props.handleHexAdd}
          closePicker={this.togglePicker}
          currentHex={this.props.currentHex}
          maxSquares={this.props.hexItems.length}
          squareSize={this.props.squareSize}
          getStarted={this.props.getStarted}
          borderWidth={this.props.borderWidth} />
      </div>
    ) : (
      // Show add icon and text
      <button 
        className=" color-selection__square-wrapper 
                    color-selection__square-wrapper--icon
                    button--anchor"
        onClick={this.togglePicker}>
        <div className="color-selection__add">
          <img 
            className="img-svg" 
            src={AddIcon}
            alt="Add Icon" />
        </div>
        <p className="text--small">
          {this.getPlaceText(this.props.index + 1)}
        </p>
      </button>
    );
  }

  render() {
    return (
      <ColorSelection 
        className="color-selection"
        hexLength={this.props.index}
        theme={this.calcPanelStyle()}>
        {this.renderPanelContent()}
      </ColorSelection>
    );
  }
}

export default ColorSelectionPanel;