import React, { Component } from 'react';
import '../../App.scss';
import './GridHeader.scss';

import styled from 'styled-components';

import HexEdit from '../HexEdit/HexEdit';

const GridHeaderContainer = styled.div`
  color: ${props => props.style.labelColor};
  background: ${props => props.style.backgroundColor};

  &::after, &::before { 
    border-width: ${props => props.borderWidth}px !important; 
  }

  & button.button--text-only {
    color: ${props => props.style.labelColor};
    // border-color: ${props => props.style.labelColor};
    // text-decoration-color: ${props => props.labelColor};
  }
`

class GridHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { editStatus: false }
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  toggleEdit = e => {
    e.preventDefault();
    this.setState({
      editStatus: !this.state.editStatus
    })
  }

  renderHeaderContent() {
    return (this.state.editStatus) ? (
      <div className="grid-header__edit-wrapper">
        <HexEdit
          hexItems={this.props.hexItems}
          handleHexEdit={this.props.handleHexEdit}
          editIndex={this.props.index}
          handleEditSubmit={this.toggleEdit} 
          squareSize={this.props.squareSize}
          coordinates={this.props.coordinates}
          borderWidth={this.props.borderWidth}
          getStarted={false}/>
      </div>
    ) : (
      <div className={"grid-header__info " + ((this.props.squareSize < 75) ? "grid-header__info--small" : "")}>
        <div className="grid-header__options">
          <button 
            className="button--text-only text--small" 
            onClick={this.toggleEdit}>
            Edit
          </button>
          <button 
            className="button--text-only text--small" 
            onClick={() => this.props.deleteHex()}>
            Remove
          </button>
        </div>
        <p className="grid-header__hex text--small-bold">
          {"#" + this.props.backgroundColor.toUpperCase()}
        </p>
        <div></div>
      </div>
    );
  }

  render() {
    let headerStyle = {
      backgroundColor: "#" + this.props.backgroundColor,
      labelColor: "#" + this.props.labelColor
    }

    return (
      <GridHeaderContainer 
        className="grid-header__container" 
        borderWidth={this.props.borderWidth}
        style={headerStyle}>
        {this.renderHeaderContent()}
      </GridHeaderContainer>
    )
  }
}

export default GridHeader;