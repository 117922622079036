import React, { Component } from 'react';
import '../../App.scss';
import './RowItem.scss';

import Grade from '../Grade/Grade';
import ColorSelectionPanel from '../ColorSelectionPanel/ColorSelectionPanel';
import RowItemOverlay from '../RowItemOverlay/RowItemOverlay';

import styled from 'styled-components';

const RowItemContainer = styled.div`
  &::after, &::before { 
    border-width: ${props => props.borderWidth}px !important; 
  }

  .row-item__content {
    background: ${props => props.theme.background} !important;
    color: ${props => props.theme.color};
  }

  & .text-sample--mobile { font-size: ${props => props.squareSize / 3}px; }
`
class RowItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      colorMatch: false,
      last: false,
      overlay: false
    }

    this.textSamples = {
      normalText : 'Normal',
      largeBoldText : 'Large Bold',
      largeText : 'Large',
      mobileText : 'Aa'
      // mobileText : this.props.coordinates.x + ',' + this.props.coordinates.y
    }

    this.checkSampleAvailability = this.checkSampleAvailability.bind(this);
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }
  
  componentDidMount() {
    this.checkSquareMatch();
  }

  checkSquareMatch() {
    // colors match: show HexAdd or hidden panel
    if (this.props.entry.text === this.props.background.text) {
      this.setState({
        colorMatch: true,
        last: this.props.lastEntry
      });
    }
  }

  toggleOverlay() {
    this.setState({ overlay: !this.state.overlay });
  }

  assignGrade() {
    let ratio = this.props.ratio;

    if (ratio == null)
      return null;
    else if (ratio >= 7)
      return 'AAA';
    else if (ratio >= 4.5)
      return 'AA';
    else if (ratio >= 3)
      return 'AA18';
    else 
      return 'DNP';
  }

  assignHiddenGradeClass() {
    let grade = this.assignGrade();
    return (!this.props.gradesDisplayed[grade]) ?
      "row-item--hidden" : "";
  }

  checkSampleAvailability(cutoff) {
    return (this.props.ratio < cutoff) ?
      "text-sample--unavailable" : "";
  }
  
  renderTextSamples() {
    return (this.props.squareSize >= 170) ? (
      <div className="text-samples">
        <p className={"text-sample--normal " + this.checkSampleAvailability(4.5)}>{this.textSamples.normalText}</p>
        <p className={"text-sample--largeBold " + this.checkSampleAvailability(3)}>{this.textSamples.largeBoldText}</p>
        <p className={"text-sample--large " + this.checkSampleAvailability(3)}>{this.textSamples.largeText}</p>
      </div>
    ) : (
      <div className="text-samples">
        <p className="text-sample--mobile">{this.textSamples.mobileText}</p>
      </div>
    );
  }

  renderOverlay() {
    if (this.state.overlay) {
      let theme = {
        background: '#' + this.props.background.text,
        color: '#' + this.props.entry.text,
        labelColor: '#' + this.props.labelColor
      }

      let contrast = {
        grade: this.assignGrade(),
        ratio: this.props.ratio
      };

      return (
        <RowItemOverlay 
          maxLength={this.props.hexItems.length}
          squareSize={this.props.squareSize}
          borderWidth={this.props.borderWidth}
          coordinates={this.props.coordinates}  
          toggleOverlay={this.toggleOverlay}
          checkSampleAvailability={this.checkSampleAvailability}
          textSamples={this.textSamples}
          theme={theme}
          contrast={contrast}/>
      );
    }
  }

  render() {
    let {colorMatch, last} = this.state;

    if (this.props.lastEntry && last) {
      return (
        (this.props.hexItems.length < 10) ?
        <RowItemContainer 
          className="row-item row-item__hex-add"
          borderWidth={this.props.borderWidth}>
          <ColorSelectionPanel
            index={this.props.hexItems.length}
            hexItems={this.props.hexItems}
            handleAddCurrent={this.props.handleAddCurrent}
            handleHexAdd={this.props.handleHexAdd}
            currentHex={this.props.currentHex}
            squareSize={this.props.squareSize}
            borderWidth={this.props.borderWidth}
            getStarted={false} />
        </RowItemContainer> :
        <RowItemContainer 
          className="row-item row-item--empty" 
          borderWidth={this.props.borderWidth} />
      );
    } 
    else if (colorMatch) {
      return (
        <RowItemContainer 
          className="row-item row-item--empty" 
          borderWidth={this.props.borderWidth} />
      );
    } 
    else {
      let theme = {
        background: '#' + this.props.background.text,
        color: '#' + this.props.entry.text,
        labelColor: '#' + this.props.labelColor
      }
      return (
        <RowItemContainer 
          className={"row-item " + this.assignHiddenGradeClass()}
          borderWidth={this.props.borderWidth}
          squareSize={this.props.squareSize}
          theme={theme}>
          <button 
            className="row-item__content button--anchor"
            tabIndex="0"
            onClick={() => this.toggleOverlay()}>
            {this.renderTextSamples()}
            <Grade  
              ratio={this.props.ratio}
              squareSize={this.props.squareSize}/>
          </button>
          {this.renderOverlay()}
        </RowItemContainer>
      );
    }
  }
}

export default RowItem;